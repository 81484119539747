import { graphql, useStaticQuery } from "gatsby"
import React, { useContext, useEffect, useState, useReducer } from "react"
import { FoodCarouselNovinky } from "../components/food-novinky"
import { Hero } from "../components/hero-black-friday"
import { Loader } from "../components/loader"
import { Order2 } from "../components/order-form2"
import { SaleFormModal2 } from "../components/sale-form-modal2"
import SEO from "../components/seo"
import { AffiliateContext } from "../contexts/AffiliateContext"
import {
  HomepageTabsProvider,
  homepageTabsReducer,
  initialState,
} from "../contexts/HomepageTabsContext"

const ReturnPageLayout = ({ site }) => {
  const [state, dispatch] = useReducer(homepageTabsReducer, initialState)
  const isEn = document.location.pathname.includes("/en")
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate
  
  useEffect(() => {
    dispatch({ type: "OPEN_TAB1" })
  }, [])

  return (
    <>
      <HomepageTabsProvider
        value={{ activeTab: state.activeTab, dispatchAction: dispatch }}
      >
        <SEO title="Return" />
        {/* componetns here */}
        <SaleFormModal2 formName="Akce 2 dny" />
        <Hero />
        <FoodCarouselNovinky />
        <Order2 id="calculator" />
      </HomepageTabsProvider>
    </>
  )
}

const ReturnPageNew = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  return (
    <>
      {site ? (
        <ReturnPageLayout site={site} isAffiliate={isAffiliate} />
      ) : (
        <Loader />
      )}
    </>
  )
}

export default ReturnPageNew
